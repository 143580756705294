.AppointmentForm {
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  background-color: #fff;
}

.InputLabel {
  flex: 0 0 3.75rem;
  font-weight: 700;
  font-size: var(--form-input-font-regular);
}
