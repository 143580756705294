.HeaderTitle {
  position: absolute;
  left: 50%;
  display: flex;
  column-gap: 0.25rem;
  align-items: center;
  transform: translateX(-50%);
}

.HeaderTitle__Text {
  font-size: var(--font-large);
  color: var(--font-black);
  text-align: center;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .HeaderTitle__Text {
    font-size: var(--font-regular);
    line-height: 1.4;
  }
}
