.StadiumSelect {
  position: relative;
}

.StadiumSelect__Select {
  max-width: 200px;
  font-weight: 700;
  font-size: var(--font-large);
  white-space: normal;
  text-align: center;
}
