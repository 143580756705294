.Button {
  width: 100%;
  height: var(--form-input-height);
  border-radius: var(--form-input-radius);
  background-color: var(--form-input-blue);
  color: var(--form-input-white);
  font-size: var(--form-input-font-regular);
}

.Button--green {
  background-color: var(--product-highlight-green) !important;
}

.Button--red {
  background-color: var(--product-highlight-orange) !important;
}

.Button--fit-content {
  height: auto;
  border-radius: 4px;
  padding: 0.3em 0.4em;
}

.Button-Search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 6px;
  background-color: var(--form-button-bg-color);
}

.Button--Delete {
  background-color: var(--form-input-bg-disabled-color);
  color: var(--form-input-black);
}

.Button--Disabled {
  background-color: var(--form-button-bg-disabled-color);
  color: var(--form-button-font-disabled-color);
  cursor: no-drop;
}

.Button-planetext {
  width: initial;
  height: initial;
  border-radius: 0;
  background-color: transparent;
  color: var(--blue);
  font-size: var(--form-input-font-regular);
  text-align: left;
  text-decoration: underline;
}
