.AppointmentForm {
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  background-color: #fff;
}

.InputLabel {
  flex: 0 0 3.75rem;
  font-weight: 700;
  font-size: var(--form-input-font-regular);
}

.InputLabel.textarea {
  padding: var(--form-input-textarea-padding);
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.LinkGuide {
  color: var(--blue);
  font-weight: 700;
  text-decoration: underline;
}
