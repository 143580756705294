.ConflictedSchedule__Item + .ConflictedSchedule__Item {
  margin-top: 0.9375rem;
}

.ConflictedSchedule__Title {
  font-size: 1rem;
}

.ConflictedScheduleList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.375rem;
  margin-top: 0.375rem;
}

.ConflictedScheduleList__Item {
  padding: 0 0.1875rem;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 0.875rem;
}

.ConflictedSchedulePopUp__Actions {
  margin-top: 0.9375rem;
}

.ConflictedScheduleList__Item[data-product-type='예약가능'] {
  border: 1px dashed var(--product-highlight-lightgrey);
  background-color: var(--product-bg-white);
  color: var(--product-font-grey);
}

.ConflictedScheduleList__Item[data-product-type='구장직접'] {
  background-color: var(--product-bg-blue);
  color: var(--product-font-blue);
}

.ConflictedScheduleList__Item[data-product-type='장기대관'] {
  background-color: var(--product-bg-purple);
  color: var(--product-font-purple);
}

.ConflictedScheduleList__Item[data-product-type='아카데미'] {
  background-color: var(--product-bg-green);
  color: var(--product-font-green);
}

.ConflictedScheduleList__Item[data-product-type='플랩대관'] {
  background-color: var(--product-bg-orange);
  color: var(--product-font-maroon);
}

.ConflictedScheduleList__Item[data-product-type='소셜매치'] {
  background-color: var(--product-bg-yellow);
  color: var(--product-font-maroon);
}

.ConflictedScheduleList__Item[data-product-type='소셜대기/대관가능'] {
  border: 1px dashed var(--product-highlight-orange);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.ConflictedScheduleList__Item[data-product-type='소셜대기/대관불가'] {
  border: 1px dashed var(--product-highlight-yellow);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.ConflictedScheduleList__Item[data-product-type='예약불가'] {
  background-color: var(--product-bg-grey);
  color: var(--product-font-grey);
}
