.SocialRequestList {
  overflow-x: auto;
  margin-top: 1rem;
}

.SocialRequestList__Title {
  font-size: var(--font-large);
}

.SocialRequestList__ItemBox {
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: default;
}

.SocialRequestList__Item--Title {
  font-weight: 700;
}

.SocialRequestList__ItemBox + .SocialRequestList__ItemBox {
  margin-top: 6px;
}

.SocialRequestList__Item {
  min-width: 140px;
  flex-shrink: 0;
}

.SocialRequestList__Item--Actions {
  display: flex;
  min-width: 170px;
  column-gap: 10px;
}
