.ZoneList {
  display: flex;
  overflow-x: auto;
  column-gap: 1rem;
}

.ZoneList__Item {
  position: relative;
}

.ZoneList__Link {
  display: block;
  font-size: 1.125rem;
  font-weight: 900;
  color: var(--font-lightgrey);
  text-align: center;
}

.ZoneList__Link:hover {
  color: var(--font-grey);
}

.ZoneList__Link.active {
  color: var(--font-black);
}

@media screen and (max-width: 1240px) {
  .ZoneList__Link {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 768px) {
  .ZoneList {
    margin-right: var(--page-wrap-right-left-padding);
    margin-left: var(--page-wrap-right-left-padding);
  }
}
