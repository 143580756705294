.SocialMatchList {
  overflow-x: auto;
  margin-top: 1rem;
}

.SocialMatchList__Title {
  font-size: var(--font-large);
}

.SocialMatchList__ItemBox + .SocialMatchList__ItemBox {
  margin-top: 6px;
}

.SocialMatchList__ItemBox {
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: default;
}

.SocialMatchList__Item--Title {
  font-weight: 700;
}

.SocialMatchList__Item {
  width: 140px;
  flex-shrink: 0;
}

.SocialMatchList__Item--Actions {
  display: flex;
  min-width: 170px;
  column-gap: 10px;
}
