.SearchForm {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
}

.SearchForm__Input {
  width: 100%;
  height: calc(2.5rem - 1px);
  padding: 0.375rem 2.5625rem 0.375rem 0.75rem;
  border: 1px solid var(--form-input-border-color);
  border-radius: 4px;
  background-color: var(--form-input-bg-color);
  color: var(--form-input-black);
  outline: none;
}

.SearchForm__SubmitBtn {
  position: absolute;
  inset: 1px 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 0 3px 3px 0;
  background-color: var(--form-input-border-color);
}

.SearchForm__Input::-webkit-calendar-picker-indicator {
  display: none !important;
}
