.Appointment {
  overflow: hidden;
  position: relative;
}

.Appointment.hidden {
  display: none;
}

.Appointment::after {
  display: block;
  width: 100%;
  padding: var(--product-monthview-block-padding);
  font-size: var(--scheduler-timetable-cell-font-regular);
  content: '\00a0';
}

.Appointment:not(:first-child) {
  margin-top: 0.5rem;
}

.Appointment__Inner {
  position: absolute;
  display: flex;
  column-gap: 0.375rem;
  padding: var(--product-monthview-block-padding);
  border-radius: var(--product-monthview-block-radius);
  cursor: pointer;
}

.Appointment__Time,
.Appointment__Consumer,
.Appointment__MoreInfo {
  font-weight: 600;
  font-size: var(--scheduler-timetable-cell-font-regular);
}

.Appointment__Consumer {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
}

@media screen and (max-width: 1024px) {
  .Appointment__Time,
  .Appointment__Consumer {
    font-size: var(--scheduler-timetable-cell-font-regular);
  }

  .Appointment__Consumer {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .Appointment__Time--Tilde,
  .Appointment__EndTime {
    display: none;
  }
}

.Appointment[data-product-type='구장직접'] .Appointment__Inner {
  background-color: var(--product-bg-blue);
  color: var(--product-font-blue);
}

.Appointment[data-product-type='장기대관'] .Appointment__Inner {
  background-color: var(--product-bg-purple);
  color: var(--product-font-purple);
}

.Appointment[data-product-type='아카데미'] .Appointment__Inner {
  background-color: var(--product-bg-green);
  color: var(--product-font-green);
}

.Appointment[data-product-type='플랩대관'] .Appointment__Inner {
  background-color: var(--product-bg-orange);
  color: var(--product-font-maroon);
}

.Appointment[data-product-type='소셜매치'] .Appointment__Inner {
  background-color: var(--product-bg-yellow);
  color: var(--product-font-maroon);
}

.Appointment[data-product-type='소셜대기/대관가능'] .Appointment__Inner {
  line-height: 1.3;
  border: 1px dashed var(--product-highlight-orange);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.Appointment[data-product-type='소셜대기/대관불가'] .Appointment__Inner {
  line-height: 1.3;
  border: 1px dashed var(--product-highlight-yellow);
  background-color: var(--product-bg-white);
  color: var(--product-font-maroon);
}

.Appointment[data-cancel-status='CANCEL'] .Appointment__Inner {
  text-decoration: line-through;
  color: var(--product-highlight-orange);
}
