.AppointmentForm {
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  background-color: #fff;
}
.InputLabel {
  flex: 0 0 3.75rem;
  font-weight: 700;
  font-size: var(--form-input-font-regular);
}

.InputLabel.hidden {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
}

.InputLabel.textarea {
  padding: var(--form-input-textarea-padding);
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.AppointmentForm__RowsWrap {
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  background-color: #fff;
}

.AppointmentForm__RowsWrap.toggle {
  overflow: visible;
  visibility: visible;
  display: flex;
  flex-direction: column;
  row-gap: 0.9375rem;
  max-height: 12.5rem;
  transition: max-height 0.4s, visibility 0.4s;
}

@media screen and (max-width: 375px) {
  .AppointmentForm__RowsWrap.toggle {
    max-height: 13.75rem;
  }
}

.AppointmentForm__RowsWrap.toggle.hidden {
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
}
